<template>
  <router-view/>
</template>
<script>

export default{

  mounted(){
   this.$store.commit('initializeStore');
  }
}

</script>


<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
</style>

